@import "./../variables";
.card {
    border-radius: 0.5rem !important;
    background-color: $theme-rustyOrange;
    color:white;
    .custom-align {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0;
        border-top-left-radius: calc(.5rem - 1px);
        border-bottom-right-radius: calc(.5rem - 1px);
        font-size: 0.85rem;
        padding: 0.15rem 0.75rem;
    }
}

.image {
    &.is-120x120 {
        height: 120px;
        width: 120px;
    }
    margin-left: -0.25rem
}
.card-bg
{
    border-radius: .75rem;
    overflow: hidden;
    color:white;
    background-color: $theme-rustyOrange;
    box-shadow:0px 13px 6px -15px grey;
    margin-bottom:1em;
    position: relative;
}
.align{
    text-align: left;
}
.playBtn
{
    position: absolute;
    padding:.35rem;
    border-top-left-radius: 10px;
    background-color: $theme-darkOrange;
    right:0;
    bottom: 0;
    display: flex;
    flex-direction: row;
     h1
     {
        padding: auto !important;
        margin:auto !important;
     }
}
.play-btn{
    margin:auto;
    width:20px;
    height: 10px;
    padding: auto;
}
.p1 {
    padding-left: 0.5rem;
}
.gamecard-wrapper {
    display: flex;
    flex-direction: row;
}

.mr {
    margin-top: -1.2rem;
}

.text-left {
    text-align: left;
}