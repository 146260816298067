.title {
    &.line {
        padding-left: 0.8rem;
        position: relative;

        &:before {
            width: 3px;
            height: 17px;
            border-radius: 2px;
            left: 0;
            top: 0;
            background: linear-gradient(0deg,#FF7600);
            content: '';
            position: absolute;
        }
    }
}

