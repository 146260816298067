@import "../variables";
.pop-bg
{
    background: rgba(217, 214, 214, 0.8);
    // background: white;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 10;
//    
}
.popup-modal
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // bottom: 0;
    width:100%;
    padding:30px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background: linear-gradient(180deg,$theme-rustyOrange,$theme-darkOrange);
    border-radius: 10px;
    // border-top-left-radius: 20px;
    // h1
    // {
    //     font-size: 1rem;
    //     // color: white;
    //     font-weight: 800;
    //     max-width: 480px;
    //     padding:0px 60px;
    //     max-width: 80%;
    //     // overflow: hidden;
    //     // overflow: auto;
    //     // padding-bottom: 10px;
    // }
}
.popup-modal2
{
    position: absolute;
    // bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:85%;
    padding:30px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    background: linear-gradient(180deg,$theme-rustyOrange,$theme-darkOrange);
    border-radius: 10px;
    // border-left-radius: 20px;
}
.rotate-img
{
    // padding: 10px;
}
.max-w
{
    width:12rem;
}
.modal-content
{
    width:80%;
    font-size:1rem;
    font-weight: 800;
     word-wrap: break-word;
     white-space: pre-line;
     color:white;
     line-height: 2;

}