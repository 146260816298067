@import './../variables';

.common {
    .slick-slider {
        .slick-list {
            .slick-slide {
                padding: 0.3rem;

                .card {
                    border-radius: 0.4rem;
                    // background-color: #d5d9e0;

                    .card-content {
                        h6 {
                            text-transform: capitalize;
                            font-size: 0.8rem;
                        }

                        p {
                            font-size: 0.7rem;
                        }
                    }
                }
            }

        }
    }
}

.title {
    &.line {
        padding-left: 0.8rem;
        position: relative;

        &:before {
            width: 3px;
            height: 17px;
            border-radius: 2px;
            left: 0;
            top: 3px;
            background: #ff4e50;
            background: linear-gradient(0deg, #f9d423, #ff4e50);
            content: '';
            position: absolute;
        }
    }
}

.is-theme {
    background-color: $theme-button-color;
    color: white;
    border-color: none;
    border-width: 0;
}
.slider-card
{
    background-color: $theme-rustyOrange;
    border-radius: 0.25rem;
    // box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    color:white;
}