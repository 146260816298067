.profile {
    // margin: 30% 5%;
    margin:40% 5%;
    width: 90%;
    .row{
        display: flex;
        color: white;
        justify-content: space-between;
        margin: 20px auto;      
    }
    .center{
        text-align: center;
    }
}
.profile-card
{
    background:linear-gradient(#FF7600, #8B040E) !important;
    color:white;
    border-radius: 5px;
    padding-bottom:5px;
    h3{
        font-size: 1.25rem;
        font-weight: 1000;
    }
    h2
    {
        font-size:0.75rem;
        font-weight: 500;
    }
}
.image
{
    height: 15px;
    padding: auto !important;
}
.user-status
{
    display: flex;
    flex-direction: row;
}
.unsubBtn
{
    margin-top: 10px;
    padding: 14px;
    font-weight: bold;
    border:2px solid transparent;
    border-radius: 30px;
}
