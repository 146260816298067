%anchor-display {
    vertical-align: baseline;
    display: inline-block;
}
.pagebanner {
    .p-r {
        position: relative;
    }
    a {
        &.navitem {
            @extend %anchor-display;
            position: absolute;
            z-index: 1;
            left: 10px;
            top: 10px;
        }
    }
    figure {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.65)), color-stop(100%, rgba(0, 0, 0, 0)));
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
        }
    }

    .fixb {
        .media {
            position: absolute;
            bottom: 0;
            display: flex;
            width: 100%;
            padding: 0.75rem 1rem;

            .media-right {
                margin-left: auto;
                align-self: flex-end;

                a {
                    @extend %anchor-display;
                }
            }
        }
    }

}
.row
{
    height:50vh;
}
.image{
    position: relative;
}
.play-btnn
{
   height: 60px;
   width:60px;
   cursor: pointer;
}
.desc
{
    position: absolute;
    bottom:10px;
}
.fixb
{
    margin-top:20px;
}
// @media screen and (min-width: 768px) {
//     .play-btnn {
//      width:100px;
//     }
//   }