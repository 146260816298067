.sideMenu {
    width: 300px;
    height: 100%;
    background-image: url("../assets/images/hamburger_menu_bg.jpg");
    z-index: 99;
    min-height: 100vh;
    color:white;
    border-radius:0 0 9% 0%;
}
.line-bottom {
    border-bottom: 1px solid #fff;
}
.menu-logo-img
{
    background-image: url("../assets/images/page_bg.jpg");
}
.menu-icon
{
    height: 30px;
}
.span
{
     color:white !important;
}
